import { render, staticRenderFns } from "./TokensView.vue?vue&type=template&id=48f40244&scoped=true"
import script from "./TokensView.vue?vue&type=script&lang=js"
export * from "./TokensView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f40244",
  null
  
)

export default component.exports