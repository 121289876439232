<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between">
        <h1 class="font-semibold text-[18px] pb-6">Create & Edit Tokens</h1>
      </div>

      <div>
        <div class="max-w-[340px]">
          <v-autocomplete
            hide-details
            class="text-[13px]"
            item-text="title"
            item-value="applovin_android_package_name"
            label="Game"
            outlined
            clearable
            dense
            :items="games"
            v-model="selectedGame"
            hide-no-data
          >
          </v-autocomplete>
        </div>

        <div class="pt-5" v-if="selectedGame">
          <v-card flat>
            <div class="flex items-center">
              <h1 class="font-semibold uppercase">Tokens</h1>
              <div class="pl-4">
                <v-btn
                  small
                  :ripple="false"
                  color="primary"
                  depressed
                  text
                  @click="createNewToken"
                  ><v-icon left dark> mdi-plus </v-icon>
                  New Token
                </v-btn>
              </div>
            </div>
            <div class="pt-5">
              <v-data-table
                :headers="headers"
                :items="tokens"
                :loading="loading"
                :items-per-page="this.limit"
                single-expand
                :expanded.sync="expanded"
                item-key="id"
                show-expand
                @click:row="handleOnRowClick"
              >
                <template v-slot:[`item.full_name`]="{ item }">
                  <div>
                    <span>{{ item.full_name }}</span>
                  </div>
                </template>
                <template v-slot:[`item.tracker_token`]="{ item }">
                  <div
                    class="inline-block bg-indigo-100 rounded-[3px] text-[10px] py-1 px-2 text-indigo-700 hover:bg-indigo-200 cursor-pointer"
                  >
                    <span>{{ item.tracker_token }}</span>
                  </div>
                </template>
                <template v-slot:[`item.created_at`]="{ item }">
                  <div>
                    <span>{{ $formatUnix(item.created_at) }}</span>
                  </div>
                </template>
                <template v-slot:[`item.idfv`]="{ item }">
                  <div>
                    <span>{{ item.idfv }}</span>
                  </div>
                </template>
                <template v-slot:[`item.idfa`]="{ item }">
                  <div>
                    <span>{{ item.idfa }}</span>
                  </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <h1 class="font-semibold">{{ item.name }} - Rules:</h1>
                    <div v-for="(rule, index) in item.rules" :key="index">
                      {{ rule }}
                    </div>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <NewTokenDialog
      :show="showCreateDialog"
      @closed="showCreateDialog = false"
      @created="handleOnTokenCreate"
      :rowData="clickedRowData"
      :mode="dialogMode"
      :package_name="selectedGame"
    />
  </div>
</template>

<script>
import NewTokenDialog from "@/components/tools/game_backend/NewTokenDialog.vue";

export default {
  data() {
    return {
      expanded: [],
      tab: 0,
      selectedGame: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "IDFA", value: "idfa" },
        { text: "IDFV", value: "idfv" },
        { text: "Created At", value: "created_at" },
        { text: "Initial Version", value: "initial_version" },
        { text: "Current Version", value: "current_version" },
        { text: "Platform", value: "platform" },
        { text: "Token", value: "tracker_token" },
        { text: "", value: "data-table-expand" },
      ],
      tokens: [],
      limit: 20,
      page: 1,
      loading: false,
      showCreateDialog: false,
      clickedRowData: null,
      dialogMode: "insert",
      games: [],
    };
  },
  watch: {
    selectedGame(newValue) {
      if (newValue) this.fetchTokens();
    },
  },
  methods: {
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data.filter(
            (x) => x.applovin_android_package_name
          );
        })
        .catch((err) => {
          this.errorHandler(err);
        });
    },
    createNewToken() {
      this.dialogMode = "insert";
      this.showCreateDialog = true;
    },
    handleOnRowClick(data) {
      this.dialogMode = "update";
      this.clickedRowData = data;
      this.showCreateDialog = true;
    },
    handleOnTokenCreate() {
      this.$toast.success("Token successfully created for given IDFV.", {
        timeout: 5000,
        position: "bottom-center",
        icon: false,
      });

      this.fetchTokens();
    },
    async fetchTokens() {
      try {
        this.loading = true;
        var url = `${process.env.VUE_APP_GAME_BACKEND_URL}/api/user/all?limit=${this.limit}&page=${this.page}&package_name=${this.selectedGame}`;
        const response = await this.$api.get(url);
        this.tokens = response.data.data;
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
  },
  computed: {},
  components: { NewTokenDialog },
  mounted() {
    this.fetchGames();
  },
};
</script>

<style lang="scss" scoped></style>
