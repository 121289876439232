<template>
  <div>
    <div class="bg-white p-6">
      <div class="flex items-center justify-between pb-3">
        <div class="flex items-center">
          <h1 class="font-semibold text-[18px]">Game Live Events</h1>
          <div class="pl-4">
            <v-autocomplete
              hide-details
              class="text-[13px]"
              item-text="title"
              return-object
              label="Game"
              outlined
              dense
              :items="games"
              v-model="selected_game"
              style="width: 230px"
            >
            </v-autocomplete>
          </div>
        </div>
        <div>
          <v-btn
            :ripple="false"
            color="secondary"
            depressed
            @click="clickCreateEventBtn"
            :loading="createLoading"
            :disabled="
              !selected_game || !selected_game.applovin_android_package_name
            "
            ><v-icon left dark> mdi-plus </v-icon> Create Live Event
          </v-btn>
        </div>
      </div>

      <div class="mt-5">
        <div class="flex items-center justify-between mb-3">
          <h1 class="font-semibold text-[14px] w-full">Live Event Calendar</h1>
          <div
            class="flex items-center"
            v-if="
              selected_game &&
              selected_game.applovin_android_package_name &&
              !loading
            "
          >
            <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon class="ma-2" @click="$refs.calendar.next()">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
        <v-calendar
          v-if="
            selected_game &&
            selected_game.applovin_android_package_name &&
            !loading
          "
          style="min-height: 600px"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          @click:event="handleOnClickEvent"
          :event-ripple="false"
          :event-text-color="''"
        >
          <template v-slot:event="{ event }">
            <div>
              <div>
                <div class="flex items-center px-1.5">
                  <span class="font-semibold">
                    {{ getHoursFromUnix(event.start) }}
                  </span>
                  <span class="ml-1.5">
                    {{ event.name }}
                  </span>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:day-label="{ day }">
            <div class="flex items-center justify-center">
              <div
                class="font-semibold text-[10px] hover:bg-gray-100 min-w-[40px] min-h-[40px] rounded-full leading-[40px]"
              >
                {{ day }}
              </div>
            </div>
          </template>
        </v-calendar>

        <div v-if="loading">
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        </div>

        <div
          v-if="!loading && !selected_game"
          class="text-[12px] p-4 rounded-[5px] bg-rose-50 text-rose-700 hover:bg-rose-100"
        >
          You need to choose a game first.
        </div>
      </div>

      <div class="mt-5">
        <h1 class="mb-3 font-semibold text-[14px]">Live Event Table</h1>
        <div class="border rounded">
          <v-data-table
            :headers="tableHeaders"
            :items="events"
            :items-per-page="10"
            @click:row="handleOnRowClick"
            :loading="createLoading || loading"
          >
            <template v-slot:[`item.id`]="{ item }">
              <span class="font-semibold text-[10px]">
                {{ item.id }}
              </span>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span class="font-semibold text-[10px]">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:[`item.start`]="{ item }">
              <span class="font-semibold text-[10px]">
                {{ $formatUnixUtc(Math.round(item.start / 1000)) }}
              </span>
            </template>
            <template v-slot:[`item.end`]="{ item }">
              <span class="font-semibold text-[10px]">
                {{ $formatUnixUtc(Math.round(item.end / 1000)) }}
              </span>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>

    <UpdateGameEventDialog
      :show="showInsertUpdateDialog"
      @closed="showInsertUpdateDialog = false"
      :data="showInsertUpdateDialogData"
      :mode="showInsertUpdateDialogMode"
      :games="games"
      @update="handleOnEventUpdate"
      @insert="handleOnEventInsert"
      @delete="handleOnEventDelete"
    ></UpdateGameEventDialog>
  </div>
</template>

<script>
import UpdateGameEventDialog from "@/components/tools/game_backend/UpdateGameEventDialog.vue";
import moment from "moment";
export default {
  data() {
    return {
      games: [],
      tableHeaders: [
        {
          text: "ID",
          value: "id",
          sortable: true,
        },
        {
          text: "Name",
          value: "name",
          sortable: true,
        },
        {
          text: "Start At",
          value: "start",
          sortable: true,
        },
        {
          text: "End At",
          value: "end",
          sortable: true,
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
        },
      ],
      type: "month",
      types: ["month", "week", "day", "4day"],
      mode: "stack",
      modes: ["stack", "column"],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
        { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
        { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
        { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      ],
      value: "",
      colors: [
        "bg-blue-100 border-blue-300 text-blue-900 border rounded hover:bg-sky-200",
        "bg-orange-100 border-orange-300 text-orange-900 border rounded hover:bg-orange-200",
        "bg-lime-100 border-lime-300 text-lime-900 border rounded hover:bg-lime-200",
        "bg-cyan-100 border-cyan-300 text-cyan-900 border rounded hover:bg-cyan-200",
        "bg-green-100 border-green-300 text-green-900 border rounded hover:bg-green-200",
        "bg-indigo-100 border-indigo-300 text-indigo-900 border rounded hover:bg-indigo-200",
        "bg-purple-100 border-purple-300 text-purple-900 border rounded hover:bg-purple-200",
      ],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Event",
        "Birthday",
        "Conference",
        "Party",
      ],
      dragEvent: null,
      dragType: null,
      showInsertUpdateDialog: false,
      showInsertUpdateDialogData: null,
      showInsertUpdateDialogMode: null,
      clickPositionX: null,
      clickPositionY: null,
      clickedEvent: null,
      events: [],
      selected_game: null,
      createLoading: false,
      loading: false,
    };
  },
  methods: {
    errorHandler(error) {
      if ("response" in error) {
        if ("data" in error["response"]) {
          const isArr = this.$isArr(error["response"]["data"]["detail"]);
          var errorMessage = error["response"]["data"]["detail"];
          if (isArr) {
            errorMessage = JSON.stringify(errorMessage);
          }
          this.$toast.error(errorMessage, {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
        return;
      } else if ("message" in error) {
        this.$toast.error(error["message"], {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      } else {
        this.$toast.error(error.message, {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
        return;
      }
    },
    handleOnRowClick(event) {
      this.showInsertUpdateDialogData = JSON.parse(JSON.stringify(event));
      this.showInsertUpdateDialogMode = "update";
      this.showInsertUpdateDialog = true;
    },
    clickCreateEventBtn() {
      const unix = Math.round(+new Date());
      this.showInsertUpdateDialogData = {
        name: "",
        start: unix,
        end: unix,
        color: null,
        timed: false,
        description: "",
        value: {},
        package_name: this.selected_game.applovin_android_package_name,
        type: "",
        no: "",
      };
      this.showInsertUpdateDialogMode = "insert";
      this.showInsertUpdateDialog = true;
    },
    async handleOnEventUpdate(event) {
      try {
        this.createLoading = true;
        const response = await this.$api.put(
          `${process.env.VUE_APP_GAME_BACKEND_URL}/api/live-events/update`,
          event
        );

        if (response.data) {
          const index = this.events.findIndex((x) => x.id === event.id);
          if (index > -1) {
            const color = JSON.parse(JSON.stringify(this.events[index].color));

            this.events.splice(index, 1);
            this.events.push({
              ...response.data,
              start: response.data.start * 1000,
              end: response.data.end * 1000,
              color: color,
            });

            this.$toast.success(
              "You have successfully updated the live event.",
              {
                timeout: 5000,
                position: "bottom-center",
                icon: false,
              }
            );
          }
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.createLoading = false;
      }
    },
    async handleOnEventInsert(event) {
      try {
        this.createLoading = true;
        const response = await this.$api.post(
          `${process.env.VUE_APP_GAME_BACKEND_URL}/api/live-events/create`,
          event
        );

        this.events.push({
          ...response.data,
          start: response.data.start * 1000,
          end: response.data.end * 1000,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
        });

        this.$toast.success("You have successfully inserted the live event.", {
          timeout: 5000,
          position: "bottom-center",
          icon: false,
        });
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.createLoading = false;
      }
    },
    async handleOnEventDelete(id) {
      try {
        this.createLoading = true;
        const response = await this.$api.delete(
          `${process.env.VUE_APP_GAME_BACKEND_URL}/api/live-events/delete?id=${id}`
        );
        if (response.data) {
          const index = this.events.findIndex((x) => x.id === id);
          if (index > -1) {
            this.events.splice(index, 1);
          }

          this.$toast.success("You have successfully deleted the live event.", {
            timeout: 5000,
            position: "bottom-center",
            icon: false,
          });
        }
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.createLoading = false;
      }
    },
    fetchGames() {
      this.$api
        .get("games/all?only_actives=True")
        .then((response) => {
          this.games = response.data.filter(
            (x) => x.applovin_android_package_name
          );
        })
        .catch((err) => {
          this.errorHandler(err);
        });
    },
    handleOnClickEvent({ event }) {
      this.showInsertUpdateDialogData = JSON.parse(JSON.stringify(event));
      this.showInsertUpdateDialogMode = "update";
      this.showInsertUpdateDialog = true;
    },
    //TODO: Package_name değişikliği applovin ?
    async fetchLiveEvents() {
      try {
        this.loading = true;
        const date = moment()
          .subtract(50, "days")
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .utc(true)
          .valueOf();

        const request = await this.$api.get(
          `${
            process.env.VUE_APP_GAME_BACKEND_URL
          }/api/live-events/all?start=${Math.round(date / 1000)}&package_name=${
            this.selected_game.applovin_android_package_name
          }`
        );
        this.events = request.data.map((x) => ({
          ...x,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          start: x.start * 1000,
          end: x.end * 1000,
        }));
      } catch (error) {
        this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    getEventColor(event) {
      if (event.isClicked) {
        return "bg-rose-100 border border-rose-300 border-dashed text-rose-300";
      } else {
        return `border border-transparent ${event.customColor}`;
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getRandomDateTimestamp() {
      // Get the current month and year
      const currentMonth = moment().month();
      const currentYear = moment().year();
      // Generate a random day within the current month
      const randomDay =
        Math.floor(
          Math.random() *
            moment(
              `${currentYear}-${currentMonth + 1}`,
              "YYYY-MM"
            ).daysInMonth()
        ) + 1;
      // Create a moment object for the random date
      const randomDate = moment(
        `${currentYear}-${currentMonth + 1}-${randomDay}`,
        "YYYY-MM-DD"
      )
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .utc(true);
      // Return the timestamp of the random date
      return randomDate.valueOf();
    },
    getHoursFromUnix(unix) {
      return moment(unix).utc(false).format("hh:mm A");
    },
  },
  components: { UpdateGameEventDialog },
  mounted() {
    this.fetchGames();
  },
  watch: {
    selected_game: {
      handler(newValue) {
        if (newValue) {
          this.fetchLiveEvents();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-calendar-weekly__day-label {
  margin: 0 !important;
}

::v-deep .v-calendar-weekly__day-label.draggable {
  background-color: rgba($color: #4a80de, $alpha: 0.1);
}
::v-deep .v-event {
  .v-event-drag-bottom {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    top: 0;
    cursor: ew-resize;

    &::after {
      display: none;
      position: absolute;
      right: 4px;
      top: 50%;
      border-top: 2px solid white;
      border-bottom: 2px solid white;
      width: 8px;
      margin-left: -8px;
      height: 7px;
      transform: translate(0, -50%);
      opacity: 0.8;
      content: "";
    }

    &:hover::after {
      display: block;
    }
  }
}

::v-deep .v-event.primary {
  background-color: inherit !important;
}
</style>
